jQuery(document).ready(function($) {

//    var gallery = $('.catalog__item-slide a');
//     //при клике на ссылку в слайде запускаем галерею
//     $('.catalog__item-slide a').on('click', function(e) {
//     e.preventDefault();
//     //узнаём индекс слайда без учёта клонов
//     var totalSlides = +$(this).parents('.slider').slick("getSlick").slideCount,
//         dataIndex = +$(this).parents('.slide').data('slick-index'),
//         trueIndex;
//     switch(true){
//         case (dataIndex<0):
//         trueIndex = totalSlides+dataIndex; break;
//         case (dataIndex>=totalSlides):
//         trueIndex = dataIndex%totalSlides; break;
//         default: 
//         trueIndex = dataIndex;
//     }  
//     //вызывается элемент галереи, соответствующий индексу слайда
//     $.fancybox.open(gallery,{}, trueIndex);
//     return false;
//     });

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
        });
    });

    $('.catalog__item-slider').slick({
        dots: true,
        arrows: true
    });

    function hideOverflow() {
        $('html').addClass('active');
        $('body').addClass('active');
    }

    function initialOverflow() {
        $('html').removeClass('active');
        $('body').removeClass('active');
    }

    $('.modal--overlay').click(function(e) {
        var clicked = $(e.target);
        if (clicked.is('.modal') || clicked.parents().is('.modal')) {
        } else {
          $('.modal--overlay').fadeOut();
          initialOverflow();
        }
    });

    //phone mask
    $('.phone-mask').mask('+375 (00) 000-00-00');

    $('.phone-mask').click(function() {
        if($(this).val() == '') {
            $(this).val('+375 (')
        }
    });

    $('.modal__close').click(function() {
        if(!$(this).next().hasClass('tg')) {
            $(this).parent().parent().fadeOut();
            initialOverflow();
        } else {
            $(this).parent().parent().parent().parent().fadeOut();
            initialOverflow();
        }
    });

    $('.btnCallModal').click(function() {
        $('.modal--overlay.callModal').fadeIn();
        hideOverflow();
    });

    $('.btnPriceModal').click(function() {
        $('.modal--overlay.priceModal').fadeIn();
        hideOverflow();
    });

    $('.quiz__col').click(function() {
        $(this).siblings().removeClass('active');
        $(this).addClass('active');
        if($(this).parent().parent().hasClass('quiz__step--first')) {
            $('#firstQuestion').val($(this).find('.quiz__text').text());
        } else if ($(this).parent().parent().hasClass('quiz__step--second')) {
            $('#secondQuestion').val($(this).find('.quiz__text').text());
        } else if ($(this).parent().parent().hasClass('quiz__step--third')) {
            $('#thirdQuestion').val($(this).find('.quiz__text').text());
        }
    });

    $('.quizBtnGoNext').click(function() {
        if($(this).parent().find('.quiz__col').hasClass('active')) {
            if($(this).parent().hasClass('quiz__step--first')) {
                $('.quiz__step--first').fadeOut();
                setTimeout(function() {
                    $('.quiz__step--first').removeClass('active');
                    $('.quiz__step--second').fadeIn();
                    $('.quiz__step--second').addClass('active');
                }, 500);
            } else if ($(this).parent().hasClass('quiz__step--second')) {
                $('.quiz__step--second').fadeOut();
                setTimeout(function() {
                    $('.quiz__step--second').removeClass('active');
                    $('.quiz__step--third').fadeIn();
                    $('.quiz__step--third').addClass('active');
                }, 500);
            } else if ($(this).parent().hasClass('quiz__step--third')) {
                $('.quiz__step--third').fadeOut();
                setTimeout(function() {
                    $('.quiz__step--third').removeClass('active');
                    $('.quiz__step--last').fadeIn();
                    $('.quiz__step--last').addClass('active');
                }, 500);
            }
        } else {
            console.log('no choosed');
        }
    });

    $('.btnStartQuiz').click(function() {
        $(this).parent().parent().parent().parent().fadeOut();
        setTimeout(function() {
            $('.quiz--overlay').css("display", "flex").hide().fadeIn();
        }, 500);
    });
    

});

